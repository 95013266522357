import { store } from '@/store/store';
import { centro_trabajo } from '@/shared/dtos/centro_trabajo';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'centro_trabajoModule',
    store,
    dynamic: true
})
class centro_trabajoModule extends VuexModule {
    public centro_trabajos: centro_trabajo[] = [];
    public centro_trabajo: centro_trabajo = new centro_trabajo();

    @Action({ commit: 'onGetcentro_trabajos' })
    public async getcentro_trabajos() {
        return await ssmHttpService.get(API.centro_trabajo);
    }
    @Action({ commit: 'onGetcentro_trabajos' })
    public async getcentro_trabajos_base() {
        return await ssmHttpService.get(API.centro_trabajo + '/todos');
    }
    @Action({ commit: 'onGetcentro_trabajo' })
    public async getcentro_trabajo(id: any) {
        return await ssmHttpService.get(API.centro_trabajo + '/' + id);
    }

    @Action
    public async guardarcentro_trabajo(centro_trabajo: centro_trabajo) {
       return await ssmHttpService.post(API.centro_trabajo, centro_trabajo.toJson());
    }

    @Action
    public async modificarcentro_trabajo(centro_trabajo: centro_trabajo) {
        return await ssmHttpService.put(API.centro_trabajo + '/' + centro_trabajo.id, centro_trabajo);
    }

    @Action
    public async eliminarcentro_trabajo(centro_trabajo: centro_trabajo) {
       return await ssmHttpService.delete(API.centro_trabajo + '/' + centro_trabajo.id, null, false);
    }

    @Mutation
    public onGetcentro_trabajos(res: centro_trabajo[]) {
        this.centro_trabajos = res ? res.map((x) => new centro_trabajo(x)) : []
    }

    @Mutation
    public onGetcentro_trabajo(res: centro_trabajo) {
        this.centro_trabajo = new centro_trabajo(res);
    }

}
export default getModule(centro_trabajoModule);